<template>
  <div
    class="draggable"
    style="
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    "
  >
    <el-table
      :data="tableData"
      ref="datatable"
      style="
        width: 100%;
        border-left: 1px solid #eee;
        border-bottom: 1px solid #eee;
      "
      height="100%"
      border
      :cell-class-name="cellClassName"
      @selection-change="handleSelectionChange"
      :stripe="showbm"
      @select="selectrow"
      row-key="id"
    >
      <el-table-column v-if="showselect" type="selection" width="55">
      </el-table-column>

      <template v-for="(item, index) in tableHeader">
        <el-table-column
          v-if="item.type == 'input-paixu'"
          :label="item.name"
          :sortable="item.sortable"
          :width="item.width"
          align="center"
        >
          <template slot-scope="scope">
            <div style="display: flex; align-items: center">
              <div style="width: 40%">
                <el-input
                  class="mytableinput"
                  type="text"
                  v-model="scope.row[item.prop]"
                  style="width: 100%; border: none"
                  @blur="changinput(scope.row)"
                ></el-input>
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  margin-left: 20px;
                "
              >
                <i
                  class="el-icon-caret-top hoverstyle"
                  style="cursor: pointer"
                  v-show="scope.row[item.prop] > 1"
                  @click="setxuhao(1, scope.row)"
                ></i>
                <i
                  class="el-icon-caret-bottom hoverstyle"
                  style="cursor: pointer"
                  v-show="scope.row[item.prop] < total"
                  @click="setxuhao(2, scope.row)"
                ></i>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          v-if="!item.type && !item.charu && !item.url"
          :fixed="item.fixed ? true : false"
          :prop="item.prop"
          :label="item.name"
          :width="item.width"
        >
          <template slot-scope="scope">
            <div v-if="item.prop == 'content'" style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
              {{ scope.row[item.prop] }}
            </div>
            <div v-else>
              {{ scope.row[item.prop] }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          v-if="item.charu"
          :fixed="item.fixed ? true : false"
          :label="item.name"
          :width="item.width"
        >
          <template slot-scope="scope">
            <p
              style="color: rgb(53, 157, 253); cursor: pointer"
              @click="clickcharu(scope.row)"
            >
              {{ scope.row[item.prop] }}
            </p>
          </template>
        </el-table-column>

        <el-table-column
          v-if="item.url"
          :fixed="item.fixed ? true : false"
          :label="item.name"
          :width="item.width"
        >
          <template slot-scope="scope">
            <p
              style="color: rgb(53, 157, 253); cursor: pointer"
              @click="gourl(scope.row[item.prop])"
            >
              {{ scope.row[item.prop] }}
            </p>
          </template>
        </el-table-column>

        <el-table-column
          v-if="item.type && item.type == 'info'"
          :label="item.name"
          :width="item.width"
        >
          <template slot-scope="scope">
            <span
              v-for="(item, index) in scope.row[item.prop]"
              v-if="item.is_check == '1'"
              >{{ item.name }}:{{ item.amoun }};</span
            >
          </template>
        </el-table-column>

        <el-table-column
          v-if="item.type && item.type == 'img'"
          :label="item.name"
          :width="item.width"
        >
          <template slot-scope="scope">
            <img
              :src="scope.row[item.prop]"
              alt=""
              :style="{
                width: '80%',
                Maxheight: item.height ? item.height : '50px',
              }"
            />
          </template>
        </el-table-column>

        <el-table-column
          v-if="item.type && item.type == 'icon'"
          :label="item.name"
          :width="item.width"
        >
          <template slot-scope="scope">
            <i :class="scope.row[item.prop]" style="font-size: 24px"></i>
          </template>
        </el-table-column>

        <el-table-column
          v-if="item.type && item.type == 'switch'"
          :label="item.name"
          :width="item.width"
        >
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row[item.prop]"
              @change="changswitch(scope.row)"
              :active-value="1"
              :inactive-value="0"
            >
            </el-switch>
          </template>
        </el-table-column>

        <el-table-column
          v-if="item.type && item.type == 'switchtype'"
          :label="item.name"
          :width="item.width"
        >
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row[item.prop]"
              @change="changswitch(scope.row)"
              active-color="#13ce66"
              :active-value="1"
              :inactive-value="2"
            >
            </el-switch>
          </template>
        </el-table-column>

        <el-table-column
          v-if="item.type && item.type == 'tags'"
          :label="item.name"
          :width="item.width"
        >
          <template slot-scope="scope">
            <el-tag v-show="scope.row[item.prop] == -999" type="info"
              >待提交</el-tag
            >
            <el-tag
              v-show="scope.row[item.prop] == 2 || scope.row[item.prop] == 5"
              type="success"
              >{{ scope.row[item.prop] == 2 ? "已立案" : "已交办" }}</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 1" type="info"
              >接收待立案</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 3" type="danger"
              >不立案</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 4" type="warning"
              >转为意见</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 6" type="success"
              >已答复</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 7" type=""
              >已提交意见表</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 11" type="info"
              >已提交</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 12" type="danger"
              >已退回</el-tag
            >
          </template>
        </el-table-column>

        <el-table-column
          v-if="item.type && item.type == 'newssh_type'"
          :label="item.name"
          :width="item.width"
        >
          <template slot-scope="scope">
            <el-tag v-show="scope.row[item.prop] == 1" type="info"
              >待审核</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 2" type="success"
              >审核通过</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 3" type="warning"
              >驳回</el-tag
            >
          </template>
        </el-table-column>

        <el-table-column
          v-if="item.type && item.type == 'wenjuan_type'"
          :label="item.name"
          :width="item.width"
        >
          <template slot-scope="scope">
            <el-tag v-show="scope.row[item.prop] == 1" type="info"
              >待开始</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 2" type="success"
              >进行中</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 3" type="danger"
              >已结束</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 5" type="warning"
              >暂存</el-tag
            >
          </template>
        </el-table-column>

        <el-table-column
          v-if="item.type && item.type == 'tags_isjy'"
          :label="item.name"
          :width="item.width"
        >
          <template slot-scope="scope">
            <el-tag v-show="scope.row[item.prop] == 1" type="warning"
              >是</el-tag
            >
            <el-tag v-show="scope.row[item.prop] != 1" type="success"
              >否</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          v-if="item.type && item.type == 'txtype'"
          :label="item.name"
          :width="item.width"
        >
          <template slot-scope="scope">
            <el-tag v-show="scope.row[item.prop] == 1" type="info"
              >未填写</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 2" type="success"
              >已填写</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          v-if="item.type && item.type == 'tags_cbdw'"
          :label="item.name"
          :width="item.width"
        >
          <template slot-scope="scope">
            <el-tag v-show="scope.row[item.prop] == 1" type="warning"
              >未答复</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 2" type="success"
              >已答复</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 3" type="success"
              >已办理</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 9" type="success"
              >已完结</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 4" type="danger"
              >有异议</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 5" type="success"
              >已采纳</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 6" type="danger"
              >不采纳</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          v-if="item.type && item.type == 'wt_types'"
          :label="item.name"
          :width="item.width"
        >
          <template slot-scope="scope">
            <el-tag v-show="scope.row[item.prop] == 1" type="success"
              >输入框</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 2" type="primary"
              >单选框</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 3" type="warning"
              >多选框</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 4" type="success"
              >文本域</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          v-if="item.type && item.type == 'tags_zdta'"
          :label="item.name"
          :width="item.width"
        >
          <template slot-scope="scope">
            <el-tag v-show="scope.row[item.prop] == 1" type="info"
              >非重点</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 2" type="success"
              >推荐重点</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 3" type="warning"
              >汇总重点</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 4" type="danger"
              >确定重点</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          v-if="item.type && item.type == 'hystatus'"
          :label="item.name"
          :width="item.width"
        >
          <template slot-scope="scope">
            <el-tag v-show="scope.row[item.prop] == 1" type="info">暂存</el-tag>
            <el-tag v-show="scope.row[item.prop] == 2" type="success"
              >已发布</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          v-if="item.type && item.type == 'tags_zzdta'"
          :label="item.name"
          :width="item.width"
        >
          <template slot-scope="scope">
            <el-tag v-show="scope.row[item.prop] == 1" type="info"
              >非重大</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 2" type="success"
              >推荐重大</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 3" type="danger"
              >确定重大</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          v-if="item.type && item.type == 'tags_zddbta'"
          :label="item.name"
          :width="item.width"
        >
          <template slot-scope="scope">
            <el-tag v-show="scope.row[item.prop] == 1" type="info"
              >非重点督办</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 2" type="success"
              >推荐重点督办</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 3" type="danger"
              >确定重点督办</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          v-if="item.type && item.type == 'tags_htk'"
          :label="item.name"
          :width="item.width"
        >
          <template slot-scope="scope">
            <el-tag v-show="scope.row[item.prop] == 1" type="info">否</el-tag>
            <el-tag v-show="scope.row[item.prop] == 2" type="success"
              >是</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          v-if="item.type && item.type == 'tags_yxta'"
          :label="item.name"
          :width="item.width"
        >
          <template slot-scope="scope">
            <el-tag v-show="scope.row[item.prop] == 2" type="info"
              >推荐优秀提案</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 3" type="success"
              >初审通过</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 4" type="">复审通过</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          v-if="item.type && item.type == 'news_type'"
          :label="item.name"
          :width="item.width"
        >
          <template slot-scope="scope">
            <el-tag v-show="scope.row[item.prop] == 1" type="success"
              >PC显示</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 2" type="info">隐藏</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          v-if="item.type && item.type == 'news_mtype'"
          :label="item.name"
          :width="item.width"
        >
          <template slot-scope="scope">
            <el-tag v-show="scope.row[item.prop] == 1" type="success"
              >app显示</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 2" type="info">隐藏</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          v-if="item.type && item.type == 'fagao_status'"
          :label="item.name"
          :width="item.width"
        >
          <template slot-scope="scope">
            <el-tag v-show="scope.row[item.prop] == 1" type="info"
              >待筛选</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 2" type="danger"
              >筛选退回</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 3" type="info"
              >待初审</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 4" type="danger"
              >初审退回</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 5" type="info"
              >待复审</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 6" type="danger"
              >复审退回</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 7" type="info"
              >待定稿</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 8" type="danger"
              >定稿退回</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 9" type="success"
              >已定稿</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 10" type="">已通知</el-tag>
            <el-tag v-show="scope.row[item.prop] == 66" type="warning"
              >待提交</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          v-if="item.type && item.type == 'meeting_status'"
          :label="item.name"
          :width="item.width"
        >
          <template slot-scope="scope">
            <el-tag v-show="scope.row[item.prop] == '待处理'" type="warning"
              >待处理</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == '已请假'" type="danger"
              >已请假</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == '已签到'" type="success"
              >已签到</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == '已参加'" type=""
              >已参加</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          v-if="item.type && item.type == 'jstype'"
          :label="item.name"
          :width="item.width"
        >
          <template slot-scope="scope">
            <el-tag v-show="scope.row[item.prop] == 10" type="info"
              >待接收</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 11" type="">已接收</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          v-if="item.type && item.type == 'jstype2'"
          :label="item.name"
          :width="item.width"
        >
          <template slot-scope="scope">
            <el-tag v-show="scope.row[item.prop] == 1" type="info"
              >待接收</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 2" type="">已接收</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          v-if="item.type && item.type == 'swzt'"
          :label="item.name"
          :width="item.width"
        >
          <template slot-scope="scope">
            {{ item.prop }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="item.type && item.type == 'huanjie'"
          :label="item.name"
          :width="item.width"
        >
          <template slot-scope="scope">
            {{
              scope.row[item.prop] == 1
                ? "待拟办"
                : scope.row[item.prop] == 2
                ? "待批示"
                : scope.row[item.prop] == 3
                ? "已批示"
                : scope.row[item.prop] == 4
                ? "待办理"
                : scope.row[item.prop] == 5
                ? "办理中"
                : scope.row[item.prop] == 6
                ? "已办理"
                : scope.row[item.prop] == 7
                ? "已归档"
                : "已归档"
            }}
          </template>
        </el-table-column>

        <el-table-column
          v-if="item.type && item.type == 'clr'"
          :label="item.name"
          :width="item.width"
        >
          <template slot-scope="scope">
            {{
              scope.row[item.prop] == 1
                ? scope.row.ybuser_name
                : scope.row[item.prop] == 2
                ? scope.row.psuser_name
                : scope.row[item.prop] == 3
                ? scope.row.user_name
                : scope.row[item.prop] == 4 || scope.row[item.prop] == 5
                ? scope.row.bl_name
                : scope.row[item.prop] == 6
                ? scope.row.user_name
                : scope.row[item.prop] == 7
                ? scope.row.user_name
                : ""
            }}
          </template>
        </el-table-column>

        <el-table-column
          v-if="item.type && item.type == 'sflb_tags'"
          :label="item.name"
          :width="item.width"
        >
          <template slot-scope="scope">
            <el-tag v-show="scope.row[item.prop] == 1" type="success"
              >拟办</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 2" type="">委批示</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          v-if="item.type && item.type == 'sffh_tags'"
          :label="item.name"
          :width="item.width"
        >
          <template slot-scope="scope">
            <el-tag v-show="scope.row[item.prop] == 1" type="success"
              >是</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 2" type="">略过复核</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          v-if="item.type && item.type == 'jjcd'"
          :label="item.name"
          :width="item.width"
        >
          <template slot-scope="scope">
            <el-tag v-show="scope.row[item.prop] == '平急'" type="info"
              >平急</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == '加急'" type="success"
              >加急</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == '特急'" type="warning"
              >特急</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == '特提'" type="danger"
              >特提</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          v-if="item.type && item.type == 'tags_sfmy'"
          :label="item.name"
          :width="item.width"
        >
          <template slot-scope="scope">
            <el-tag v-show="scope.row[item.prop] == 1" type="danger"
              >不满意</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 2" type="info"
              >基本满意</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 3" type="success"
              >满意</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          v-if="item.type && item.type == 'get_type'"
          :label="item.name"
          :width="item.width"
        >
          <template slot-scope="scope">
            <el-tag v-show="scope.row[item.prop] == 1" type="info"
              >待拟办</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 2" type="info"
              >待批示</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 3" type="">已批示</el-tag>
            <el-tag v-show="scope.row[item.prop] == 4" type="info"
              >待办理</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 5" type="info"
              >办理中</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 6" type="info"
              >已办理</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 7" type="success"
              >已归档</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          v-if="item.type && item.type == 'send_type'"
          :label="item.name"
          :width="item.width"
        >
          <template slot-scope="scope">
            <el-tag v-show="scope.row[item.prop] == 1" type="info"
              >待审核</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 2" type="">审核通过</el-tag>
            <el-tag v-show="scope.row[item.prop] == 3" type="info"
              >待复核</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 4" type="">复核通过</el-tag>
            <el-tag v-show="scope.row[item.prop] == 5" type="info"
              >待签发</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 8" type="">已签发</el-tag>
            <el-tag v-show="scope.row[item.prop] == 9" type="success"
              >已归档</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          v-if="item.type && item.type == 'zt_fanwen'"
          :label="item.name"
          :width="item.width"
        >
          <template slot-scope="scope">
            <el-tag v-show="scope.row[item.prop] == 3" type="danger"
              >签发驳回</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 2" type="danger"
              >复审驳回</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 1" type="">暂存</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          v-if="item.type && item.type == 'sqmy_type'"
          :label="item.name"
          :width="item.width"
        >
          <template slot-scope="scope">
            <el-tag v-show="scope.row[item.prop] == 1" type="info"
              >待审核</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 2" type="success"
              >审核通过</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 3" type="danger"
              >驳回</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 4" type="success"
              >复审通过</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 5" type="danger"
              >复审驳回</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          v-if="item.type && item.type == 'yscyj'"
          :label="item.name"
          :width="item.width"
        >
          <template slot-scope="scope">
            <el-tag v-show="scope.row[item.prop] == '建议立案'" type="success"
              >建议立案</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == '建议转意见'" type="warning"
              >建议转意见</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == '建议不立案'" type="danger"
              >建议不立案</el-tag
            >
          </template>
        </el-table-column>

        <el-table-column
          v-if="item.type && item.type == 'lvzsbtype'"
          :label="item.name"
          :width="item.width"
        >
          <template slot-scope="scope">
            <el-tag v-show="scope.row[item.prop] == 0" type="info"
              >待审核</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 1" type="success"
              >审核通过</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 2" type="danger"
              >驳回</el-tag
            >
          </template>
        </el-table-column>

        <!--履职表和驿站新闻共用-->
        <el-table-column
          v-if="item.type && item.type == 'status_lztb'"
          :label="item.name"
          :width="item.width"
        >
          <template slot-scope="scope">
            <el-tag v-show="scope.row[item.prop] == 0" type="info"
              >待审核</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 1" type="success"
              >审核通过</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 2" type="danger"
              >驳回</el-tag
            >
          </template>
        </el-table-column>

        <el-table-column
          v-if="item.type && item.type == 'lvbg_status'"
          :label="item.name"
          :width="item.width"
        >
          <template slot-scope="scope">
            <el-tag v-show="scope.row[item.prop] == 0" type="warning"
              >暂存</el-tag
            >
            <el-tag v-show="scope.row[item.prop] == 1" type="success"
              >已提交</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          v-if="item.type && item.type == 'zid_tbn'"
          :label="item.name"
          :width="item.width"
        >
          <template slot-scope="scope">
            <el-tag v-show="!scope.row[item.prop]" type="info">未合并</el-tag>
            <el-button
              v-show="scope.row[item.prop]"
              type="success"
              size="mini"
              @click="lookhb(scope.row)"
              >查看合并</el-button
            >
          </template>
        </el-table-column>

        <el-table-column
          v-if="item.type && item.type == 'input'"
          :label="item.name"
          :width="item.width"
        >
          <template slot-scope="scope">
            <el-input
              v-if="scope.row.go_account_status != 1"
              v-model="scope.row[item.prop]"
              placeholder="请输入"
              style="width: 80%"
              @change="changeinput(scope.row)"
            ></el-input>
            <span v-if="scope.row.go_account_status == 1">{{
              scope.row[item.prop]
            }}</span>
          </template>
        </el-table-column>
      </template>

      <el-table-column
        v-if="showcz"
        fixed="right"
        label="操作"
        :width="czwidth"
        class-name="tcboxbtn"
      >
        <template slot-scope="scope" class="tcboxbtn">
          <slot name="btns" :scope="scope"> </slot>
        </template>
      </el-table-column>
    </el-table>

    <div
      v-if="showpages"
      style="
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 10px;
      "
    >
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="inputpage"
        :page-sizes="[5, 15, 30, 100]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import Sortable from "sortablejs";
export default {
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    tableHeader: {
      type: Array,
      default: () => [],
    },
    showcz: {
      type: Boolean,
      default: true,
    },
    showselect: {
      type: Boolean,
      default: false,
    },
    showpages: {
      type: Boolean,
      default: true,
    },
    showbm: {
      type: Boolean,
      default: false,
    },
    czwidth: {
      type: Number,
      default: 100,
    },
    rowdraggable: {
      //数据拖动
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputpage: 1,
      pagesize: 15,
      total: 0,
      selectdata: [],
    };
  },
  mounted() {
    if (this.rowdraggable) {
      this.rowDrop();
    }
  },
  methods: {
    // 行拖拽
    rowDrop() {
      // 此时找到的元素是要拖拽元素的父容器
      const tbody = document.querySelector(
        ".draggable .el-table__body-wrapper tbody"
      );
      const _this = this;
      Sortable.create(tbody, {
        //  指定父元素下可被拖拽的子元素
        draggable: ".draggable .el-table__row",
        onEnd({ newIndex, oldIndex }) {
          _this.$emit("rowdrop", {
            newrow: _this.tableData[newIndex],
            oldrow: _this.tableData[oldIndex],
          });
          const currRow = _this.tableData.splice(oldIndex, 1)[0];
          _this.tableData.splice(newIndex, 0, currRow);
        },
      });
    },
    changinput(row) {
      this.$emit("changinput", row);
    },
    setxuhao(type, row) {
      this.$emit("changxu", { type: type, row: row });
    },
    lookhb(item) {
      this.$emit("lookhb", item);
    },
    toggleSelection(rows) {
      //回显打勾
      this.$nextTick(() => {
        if (rows) {
          rows.forEach((row) => {
            this.$refs.datatable.toggleRowSelection(row);
          });
        } else {
          this.$refs.datatable.clearSelection();
        }
      });
    },
    selectrow(se, row) {
      console.log(row);
      this.$emit("selectdata", row);
    },
    clearselect() {
      this.$refs.datatable.clearSelection();
    },

    setgouxuan() {
      //设置勾选的数据
      this.$nextTick(() => {
        this.$refs.datatable.clearSelection();
        this.selectdata.forEach((row) => {
          this.$refs.datatable.toggleRowSelection(row, true);
        });
      });
    },
    gourl(row) {
      // window.open(this.$URL + row)
    },
    clickcharu(row) {
      this.$emit("clickcharu", row);
    },
    changswitch(row) {
      this.$emit("changswitch", row);
    },
    changeinput(row) {
      this.$emit("changeinput", row);
    },
    handleSizeChange(val) {
      this.pagesize = val;
      this.$emit("getdata");
    },
    handleCurrentChange(val) {
      this.inputpage = val;
      this.$emit("getdata");
    },
    handleSelectionChange(val) {
      this.$emit("handleSelectionChange", val);
    },
    cellClassName({ row, column, rowIndex, columnIndex }) {
      var data = JSON.parse(JSON.stringify(this.tableHeader));
      let classname = "";
      if (columnIndex < data.length) {
        if (data[columnIndex].stylecss) {
          classname = "green_status";
        } else {
          classname = "";
        }
      }
      return classname;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .tcboxbtn .cell {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
::v-deep .el-pagination {
  display: flex;
}
.green_status div {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f;
  box-sizing: border-box;
  padding: 0 7px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  width: fit-content;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: fit-content;
}

.notgreen_status div {
  color: rgba(0, 0, 0, 0.65);
  background: rgb(250, 250, 250);
  border-color: #d9d9d9;
  box-sizing: border-box;
  padding: 0 7px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  width: fit-content;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: fit-content;
}
::v-deep .el-table__fixed-right:before {
  background-color: #fff !important;
}
::v-deep .has-gutter .el-checkbox {
  display: none !important;
}
</style>
