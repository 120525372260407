var routerdata = [
	{
		path: '/home',
		name: '首页',
		url: '/home/home.vue',
		icon: "el-icon-house",
		meta:{
			isdan:true
		}
	},	
	{
		icon: "el-icon-user",
		name: '新闻管理',
		children: [		
			{
				path: '/system/news_type',
				name: '新闻分类管理',
				url: '/news/news/news_type.vue'
			},
			{
				path: '/system/news',
				name: '新闻管理',
				url: '/news/news/news.vue',
				meta:{
					keepalive:true
				}
			},
			{
				path: '/system/news_add',
				name: '新增新闻',
				url: '/news/news/addnews.vue',
				meta:{
					ischildren:true
				}
			},
			{
				path: '/system/news_edit',
				name: '编辑新闻',
				url: '/news/news/editnews.vue',
				meta:{
					ischildren:true
				}
			},
			{
				path: '/system/url_type',
				name: '链接分类管理',
				url: '/news/url/url_type.vue'
			},
			{
				path: '/system/url',
				name: '链接管理',
				url: '/news/url/url.vue'
			},
			{
				path: '/system/other_url',
				name: '其他网站管理',
				url: '/news/other_url/other_url.vue'
			},
			{
				path: '/system/addnews',
				name: '上报新闻',
				url: '/news/add/add.vue'
			},
			{
				path: '/system/news_sh',
				name: '新闻审核',
				url: '/news/news_sh/news_sh.vue'
			},
			{
				path: '/system/news_list',
				name: '新闻列表',
				url: '/news/news_list/news_list.vue'
			}
		]
	},
	{
		icon: "el-icon-document",
		name: '提案管理',
		children:[
			{
				path: '/system/lian_zhang',
				name: '立案章管理',
				url: '/tian_mng/lian_zhang/lian_zhang.vue',				
			},
			{
				path: '/system/zhengji_list',
				name: '提案征集通知',
				url: '/tian_mng/zhengji/zhengji.vue',
				meta:{
					keepalive:true
				}
			},
			{
				path: '/system/add_zhengji',
				name: '新增提案征集通知',
				url: '/tian_mng/zhengji/add_zhengji.vue',
				meta:{
					ischildren:true
				}
			},
			{
				path: '/system/edit_zhengji',
				name: '编辑提案征集通知',
				url: '/tian_mng/zhengji/edit_zhengji.vue',
				meta:{
					ischildren:true
				}
			},
			{
				path: '/system/list_yushen',
				name: '提案预审查',
				url: '/tian_mng/list_yushen/yushen.vue',	
				meta:{
					keepalive:true
				}
			},
			{
				name: '提案预审查意见汇总',
				children:[
					{			
						path: '/system/list_yushen_tian_hz',
						name: '以提案汇总',
						url: '/tian_mng/list_yushen/tian_hz.vue',
					},
					{
						path: '/system/list_yushen_weiyuan_hz',
						name: '以委员汇总',
						url: '/tian_mng/list_yushen/weiyuan_hz.vue',
					}
				]
			},
			{
				path: '/system/tian_list',
				name: '提案列表',
				url: '/tian_mng/list/list.vue',		
				meta:{
					keepalive:true
				}
			},
			{
				path: '/system/code_hz',
				name: '提案号编辑汇总',
				url: '/tian_mng/code_hz/code_hz.vue',		
				meta:{
					keepalive:true
				}
			},
			{
				path: '/system/list_yilian',
				name: '已立案提案',
				url: '/tian_mng/list_yilian/list.vue',	
				meta:{
					keepalive:true
				}
			},
			{
				path: '/system/list_bulian',
				name: '不予立案提案',
				url: '/tian_mng/list_bulian/list.vue',
				meta:{
					keepalive:true
				}
			},
			{
				name: '转意见提案',
				children:[
					{			
						path: '/system/list_yj_list_wylx',
						name: '委员来信',
						url: '/tian_mng/yj_list/wylx.vue',
					},
					{
						path: '/system/list_yj_list_sqmy',
						name: '社情民意',
						url: '/tian_mng/yj_list/sqmy.vue',
					},
					{
						path: '/system/list_yj_list_yjjy',
						name: '意见建议',
						url: '/tian_mng/yj_list/yjjy.vue',
					}
				]
			},
			{
				path: '/system/list_yijieshou',
				name: '已接收提案',
				url: '/tian_mng/list_yijieshou/list.vue',
				meta:{
					keepalive:true
				}
			},
			{
				path: '/system/list_tuihui',
				name: '已退回提案',
				url: '/tian_mng/list_tuihui/list.vue',
				meta:{
					keepalive:true
				}
			},
			{
				path: '/system/edit_tian',
				name: '编辑提案',
				url: '/tian_mng/list/edit.vue',
				meta:{
					ischildren:true
				}
			},
			{
				path: '/system/tian_jiaoban',
				name: '提案交办',
				url: '/tian_mng/jiaoban/list.vue',				
			},
			{
				name: '承办单位处理',
				children:[
					{			
						path: '/system/tian_list_cbdw_daichuli',
						name: '待处理',
						url: '/tian_mng/list_cbdw/daichuli/daichuli.vue',				
					},
					{
						path: '/system/tian_list_cbdw_yiyi',
						name: '提出异议',
						url: '/tian_mng/list_cbdw/yiyi/yiyi.vue',				
					},
					{
						path: '/system/tian_list_cbdw_yichengnuo',
						name: '已承诺',
						url: '/tian_mng/list_cbdw/yichengnuo/yichengnuo.vue',				
					},
					{
						path: '/system/tian_list_cbdw_yidafu',
						name: '已答复',
						url: '/tian_mng/list_cbdw/yidafu/yidafu.vue',				
					},
					{
						path: '/system/tian_list_cbdw_finish',
						name: '已完结',
						url: '/tian_mng/list_cbdw/finish/finish.vue',				
					}
				]
			},
			{
				path: '/system/tian_hebing',
				name: '合并提案',
				url: '/tian_mng/list/hebing/hebing.vue',
				meta:{
					ischildren:true
				}
			},
			{
				path: '/system/tian_hebing_list',
				name: '已合并提案管理',
				url: '/tian_mng/hebing_list/hebing_list.vue',				
			},
			{
				path: '/system/tian_important_hz_list',
				name: '汇总重点提案',
				url: '/tian_mng/important_hz_list/important_hz_list.vue'
			},
			{
				path: '/system/tian_important_qr_list',
				name: '确认重点提案',
				url: '/tian_mng/important_qr_list/important_qr_list.vue'
			},
			{
				path: '/system/tian_important_list',
				name: '重点提案',
				url: '/tian_mng/important_list/important_list.vue'
			},
			{
				path: '/system/tian_big_list',
				name: '重大提案',
				url: '/tian_mng/big_list/big_list.vue'
			},
			{
				path: '/system/tian_import_db_list',
				name: '重点督办提案',
				url: '/tian_mng/import_db_list/import_db_list.vue'
			},
			{
				path: '/system/tian_htk_list',
				name: '回头看提案',
				url: '/tian_mng/htk_list/htk_list.vue'
			},
			{
				path: '/system/tian_good_list',
				name: '优秀提案',
				url: '/tian_mng/good_list/good_list.vue'
			},
			{
				path: '/system/tian_good_cbdw_list',
				name: '优秀承办单位确认',
				url: '/tian_mng/good_cbdw_list/good_cbdw_list.vue'
			},
			{
				path: '/system/tian_good_cbdw_sure_list',
				name: '优秀承办单位',
				url: '/tian_mng/good_cbdw_sure_list/good_cbdw_sure_list.vue'
			},
			{
				path: '/system/tian_type_set',
				name: '提案类型管理',
				url: '/tian_mng/type_set/type_set.vue'
			}
		]
	},	
	{
		icon: "el-icon-document",
		name: '我的提案管理',
		children:[	
			{
				path: '/system/tian_list_mine_tz',
				name: '我的提案通知',
				url: '/tian_mng_mine/mine_tz/mine_tz.vue',				
			},
			{
				path: '/system/tian_list_mine',
				name: '我的提案列表',
				url: '/tian_mng_mine/list/list.vue',
				meta:{
					keepalive:true
				}
			},
			{
				path: '/system/add_tian_mine',
				name: '新增提案',
				url: '/tian_mng_mine/list/add.vue',
				meta:{
					ischildren:true
				}
			},
			{
				path: '/system/edit_tian_mine',
				name: '编辑提案',
				url: '/tian_mng_mine/list/edit.vue',
				meta:{
					ischildren:true
				}
			},
			{
				path: '/system/tian_important_list_mine',
				name: '我的重点提案',
				url: '/tian_mng_mine/important_list/important_list.vue'
			},
			{
				path: '/system/tian_good_list_mine',
				name: '我的优秀提案',
				url: '/tian_mng_mine/good_list/good_list.vue'
			},
		]
	},	
	{
		icon: "el-icon-user",
		name: '角色管理',
		children: [			
			{
				path: '/system/account',
				name: '账号管理',
				url: '/permission/account/account.vue'
			},{
				path: '/system/account_add',
				name: '新增账号',
				url: '/permission/account/account_add.vue',
				meta:{
					ischildren:true
				}
			},{
				path: '/system/account_edit',
				name: '编辑账号',
				url: '/permission/account/account_edit.vue',
				meta:{
					ischildren:true
				}
			},
			{
				path: '/system/role',
				name: '角色管理',
				url: '/permission/role/role.vue'
			},
		]
	},
	{
		icon: "el-icon-reading",
		name: '文史资料',
		children: [
			{
				path: '/book_mng/type',
				name: '书籍分类',
				url: '/book_mng/type.vue'
			},
			{
				path: '/book_mng/content',
				name: '书籍章节',
				url: '/book_mng/content.vue'
			}
		]
	}
	,
	{
		icon: "el-icon-menu",
		name: '组织架构',
		children: [
			{
				path: '/organization/zzjiagou',
				name: '人员架构',
				url: '/organization/zzjiagou.vue'
			},
			{
				path: '/organization/zzjiagou2',
				name: '承办单位架构',
				url: '/organization/zzjiagou2.vue'
			}
		]
	},
	{
		icon: "el-icon-menu",
		name: '系统配置',
		children: [
			{
				path: '/system/menu_list',
				name: '菜单配置',
				url: '/system/menu_set/menu_list.vue'
			},
			{
				path: '/system/menu_add',
				name: '新增菜单',
				url: '/system/menu_set/menu_add.vue',
				meta:{
					ischildren:true
				}
			},
			{
				path: '/system/menu_edit',
				name: '编辑菜单',
				url: '/system/menu_set/menu_edit.vue',
				meta:{
					ischildren:true
				}
			},
			{
				path: '/system/login_log',
				name: '登录日志',
				url: '/system/login_log/login_log.vue'
			}
		]
	},
	{
		icon: "el-icon-document",
		name: '会议管理',
		children:[			
			{
				path: '/system/meeting_list',
				name: '会议列表',
				url: '/meeting/meeting_list/meeting_list.vue',
				meta:{
					keepalive:true
				}
			},
			{
				path: '/system/meeting_add',
				name: '新增会议',
				url: '/meeting/meeting_list/add.vue',	
				meta:{
					ischildren:true
				}
			},
			{
				path: '/system/meeting_edit',
				name: '编辑会议',
				url: '/meeting/meeting_list/edit.vue',	
				meta:{
					ischildren:true
				}
			},
			{
				path: '/system/meeting_room',
				name: '会议室管理',
				url: '/meeting/room/room.vue',				
			},
			{
				path: '/system/meeting_tz',
				name: '我的会议',
				url: '/meeting/meeting_tz/meeting_tz.vue',				
			},
			{
				path: '/system/meeting_qj',
				name: '请假管理',
				url: '/meeting/meeting_qj/meeting_qj.vue',				
			}
		]
	},
	{
		icon: "el-icon-document",
		name: '大会发言管理',
		children:[			
			{
				path: '/system/talk_list',
				name: '大会发言征集',
				url: '/meeting_talk/talk_list/talk_list.vue',				
			},
			{
				path: '/system/talk_list_add',
				name: '新增大会发言征集',
				url: '/meeting_talk/talk_list/add.vue',
				meta:{
					ischildren:true
				}
			},
			{
				path: '/system/talk_list_edit',
				name: '修改大会发言征集',
				url: '/meeting_talk/talk_list/edit.vue',
				meta:{
					ischildren:true
				}
			},
			{
				path: '/system/talk_tz',
				name: '大会发言通知',
				url: '/meeting_talk/talk_tz/talk_tz.vue',				
			},
			{
				path: '/system/talk_allfagao',
				name: '大会发稿列表',
				url: '/meeting_talk/talk_allfagao/talk_allfagao.vue',				
			},
			{
				path: '/system/talk_myfagao',
				name: '我的发稿列表',
				url: '/meeting_talk/talk_myfagao/talk_myfagao.vue',				
			},
			{
				path: '/system/talk_fg_add',
				name: '新增大会发稿',
				url: '/meeting_talk/add_fagao.vue',
				meta:{
					ischildren:true
				}
			},{
				path: '/system/talk_fg_edit',
				name: '编辑稿件',
				url: '/meeting_talk/edit_fagao.vue',
				meta:{
					ischildren:true
				}
			},
			{
				path: '/system/talk_shaixuan',
				name: '大会发稿筛选',
				url: '/meeting_talk/talk_shaixuan/talk_shaixuan.vue',				
			},
			{
				path: '/system/talk_chushen',
				name: '大会发稿初审',
				url: '/meeting_talk/talk_chushen/talk_chushen.vue',				
			},
			{
				path: '/system/talk_fushen',
				name: '大会发稿复审',
				url: '/meeting_talk/talk_fushen/talk_fushen.vue',				
			},
			{
				path: '/system/talk_dinggao',
				name: '大会发稿定稿',
				url: '/meeting_talk/talk_dinggao/talk_dinggao.vue',				
			},
			{
				path: '/system/talk_all',
				name: '发言材料列表',
				url: '/meeting_talk/finish_all/finish_all.vue',				
			},
			{
				path: '/system/talk_mine',
				name: '我的发言材料',
				url: '/meeting_talk/finish_mine/finish_mine.vue',				
			},
		]
	},
	{
		icon: "el-icon-document",
		name: '公文流转系统',
		children:[		
			{
				path: '/system/yinzhang',
				name: '印章管理',
				url: '/public_file/yinzhang_mng/yinzhang_mng.vue',				
			},
			{
				path: '/system/qianming_mng',
				name: '签名管理',
				url: '/public_file/qianming_mng/qianming_mng.vue',				
			},
			{				
				name: '收文管理',
				children:[
					{
						path: '/system/get_file/index',
						name: '公文管理工作台',
						url: '/public_file/get_file_mng/index.vue',				
					},
					{			
						path: '/system/get_file/caogao',
						name: '草稿箱',
						url: '/public_file/get_file_mng/caogao/caogao.vue',				
					},
					{
						path: '/system/get_file/add',
						name: '新增公文',
						url: '/public_file/get_file_mng/addget.vue',				
					},
					{
						path: '/system/get_file/edit',
						name: '编辑公文',
						url: '/public_file/get_file_mng/editget.vue',				
					},
					{
						path: '/system/get_file/daiban',
						name: '待办理',
						url: '/public_file/get_file_mng/aaa_new_dai/daiban.vue',
					},
					{
						path: '/system/get_file/zaiban',
						name: '在办理',
						url: '/public_file/get_file_mng/aaa_new_zai/zaiban.vue',
					},
					{
						path: '/system/get_file/yiban',
						name: '已办理',
						url: '/public_file/get_file_mng/aaa_new_yi/yiban.vue',
					},
					{
						path: '/system/get_file/aaa_new_my',
						name: '我的收文',
						url: '/public_file/get_file_mng/aaa_new_my/aaa_new_my.vue',
					},
					// {
					// 	path: '/system/get_file/dainban',
					// 	name: '待拟办',
					// 	url: '/public_file/get_file_mng/dainban/dainban.vue',				
					// },
					// {
					// 	path: '/system/get_file/daipi',
					// 	name: '待批示',
					// 	url: '/public_file/get_file_mng/daipi/daipi.vue',				
					// },
					// {
					// 	path: '/system/get_file/yipi',
					// 	name: '已批示',
					// 	url: '/public_file/get_file_mng/yipi/yipi.vue',				
					// },
					// {
					// 	path: '/system/get_file/daildsp',
					// 	name: '待领导批示',
					// 	url: '/public_file/get_file_mng/daildsp/daildsp.vue',				
					// },
					// {
					// 	path: '/system/get_file/daijiaoban',
					// 	name: '待交办',
					// 	url: '/public_file/get_file_mng/daijiaoban/daijiaoban.vue',				
					// },
					// {
					// 	path: '/system/get_file/daibanli',
					// 	name: '待办理',
					// 	url: '/public_file/get_file_mng/daibanli/daibanli.vue',				
					// },
					// {
					// 	path: '/system/get_file/yibanli',
					// 	name: '已办理',
					// 	url: '/public_file/get_file_mng/yibanli/yibanli.vue',				
					// },
					// {
					// 	path: '/system/get_file/yigd',
					// 	name: '已归档',
					// 	url: '/public_file/get_file_mng/yigd/yigd.vue',				
					// },
					{
						path: '/system/get_file/alllist',
						name: '收文列表',
						url: '/public_file/get_file_mng/all_list/all_list.vue',				
					}
				]
			},
			{				
				name: '发文管理',
				children:[
					{			
						path: '/system/send_file_mng/caogao',
						name: '我的发文',
						url: '/public_file/send_file_mng/caogao/caogao.vue',				
					},
					{
						path: '/system/send_file_mng/add',
						name: '新增公文',
						url: '/public_file/send_file_mng/addget.vue',				
					},
					{
						path: '/system/send_file_mng/edit',
						name: '编辑公文',
						url: '/public_file/send_file_mng/editget.vue',				
					},
					{
						path: '/system/send_file_mng/editnumber',//只能编辑政宁号和交印日期
						name: '编辑公文',
						url: '/public_file/send_file_mng/edit_number.vue',				
					},
					{
						path: '/system/send_file_mng/daishenghe',
						name: '待审核',
						url: '/public_file/send_file_mng/daishenghe/daishenghe.vue',				
					},
					{
						path: '/system/send_file_mng/shenghe_pass',
						name: '审核通过',
						url: '/public_file/send_file_mng/shenghe_pass/shenghe_pass.vue',				
					},
					{
						path: '/system/send_file_mng/daifuhe',
						name: '待复核',
						url: '/public_file/send_file_mng/daifuhe/daifuhe.vue',				
					},
					{
						path: '/system/send_file_mng/fuhe_pass',
						name: '复核通过',
						url: '/public_file/send_file_mng/fuhe_pass/fuhe_pass.vue',				
					},
					{
						path: '/system/send_file_mng/daiqianfa',
						name: '待签发',
						url: '/public_file/send_file_mng/daiqianfa/daiqianfa.vue',				
					},
					{
						path: '/system/send_file_mng/daijiaoban',
						name: '待交办',
						url: '/public_file/send_file_mng/daijiaoban/daijiaoban.vue',				
					},
					{
						path: '/system/send_file_mng/daibanli',
						name: '待办理',
						url: '/public_file/send_file_mng/daibanli/daibanli.vue',				
					},
					{
						path: '/system/send_file_mng/yibanli',
						name: '已签发',
						url: '/public_file/send_file_mng/yibanli/yibanli.vue',				
					},
					{
						path: '/system/send_file_mng/yigd',
						name: '已归档',
						url: '/public_file/send_file_mng/yigd/yigd.vue',				
					},
					{
						path: '/system/send_file_mng/daichaos',
						name: '待抄送',
						url: '/public_file/send_file_mng/daichaos/daichaos.vue',				
					},
					{
						path: '/system/send_file_mng/yichaos',
						name: '已抄送',
						url: '/public_file/send_file_mng/yichaos/yichaos.vue',				
					},
					{
						path: '/system/send_file_mng/chaoslist',
						name: '接收列表',
						url: '/public_file/send_file_mng/chaoslist/chaoslist.vue',				
					},
					{
						path: '/system/send_file_mng/alllist',
						name: '发文列表',
						url: '/public_file/send_file_mng/all_list/all_list.vue',				
					}
				]
			}
		]
	},
	{
		icon: "el-icon-notebook-2",
		name: '社情民意',
		children:[		
			{
				path: '/system/people_opinions_list_mine',
				name: '我的社情民意',
				url: '/people_opinions/list_mine.vue',
			},
			{
				path: '/system/people_opinions_list_add',
				name: '新增社情民意',
				url: '/people_opinions/add.vue',
			},
			{
				path: '/system/people_opinions_list',
				name: '社情民意列表',
				url: '/people_opinions/list.vue',
			},
			{
				path: '/system/people_opinions_listfs',
				name: '社情民意复审列表',
				url: '/people_opinions/list_fs.vue',
			},
			{
				path: '/system/people_opinions_listsh',
				name: '社情民意审核',
				url: '/people_opinions/list_sh.vue',
			},
			{
				path: '/system/people_opinions_type',
				name: '社情民意类别管理',
				url: '/people_opinions/my_type.vue',
			},
		]
	},
	{
		icon: "el-icon-notebook-2",
		name: '委员驿站',
		children:[			
			{
				path: '/system/yun_yizhan_list',
				name: '驿站列表',
				url: '/yun_yizhan/yizhan_list/yizhan_list.vue',
				meta:{
					keepalive:true
				}
			},
			{
				path: '/system/yun_yizhan_listadd',
				name: '新增驿站',
				url: '/yun_yizhan/yizhan_list/add.vue',
			},
			{
				path: '/system/yun_yizhan_listedit',
				name: '编辑驿站',
				url: '/yun_yizhan/yizhan_list/edit.vue',
			},
			{
				path: '/system/yun_yizhan_active',
				name: '场地预约管理',
				url: '/yun_yizhan/yizhan_active/yizhan_active.vue',
			},
			{
				path: '/system/yun_yizhan_roomlist',
				name: '场地管理',
				url: '/yun_yizhan/roomlist/roomlist.vue',
				meta:{
					keepalive:true
				}
			},
			{
				path: '/system/yun_roomadd',
				name: '新增场地',
				url: '/yun_yizhan/roomlist/add.vue',
			},
			{
				path: '/system/yun_roomedit',
				name: '编辑场地',
				url: '/yun_yizhan/roomlist/edit.vue',
			},
			{
				path: '/system/yun_yizhan_mine_news',
				name: '我的驿站新闻',
				url: '/yun_yizhan/news/mine_news.vue',
				meta:{
					keepalive:true
				}
			},
			{
				path: '/system/yun_yizhan_all_news',
				name: '全部驿站新闻',
				url: '/yun_yizhan/news/all_news.vue',
			},
			{
				path: '/system/yun_yizhan_news_sheng',
				name: '驿站新闻审核',
				url: '/yun_yizhan/news/news_sheng.vue',
			},
			{
				path: '/system/yun_yizhan_newsdetail',
				name: '驿站新闻详情',
				url: '/yun_yizhan/news/detail.vue',
			},
			{
				path: '/system/yun_yizhan_newsadd',
				name: '新增驿站新闻',
				url: '/yun_yizhan/news/add.vue',
			},
			{
				path: '/system/yun_yizhan_newsedit',
				name: '编辑驿站新闻',
				url: '/yun_yizhan/news/edit.vue',
			},
		]
	},
	{
		icon: "el-icon-discount",
		name: '活动管理',
		children:[	
			{
				path: '/system/activity_typelist',
				name: '活动分类',
				url: '/activity_mng/activity_type/activity_type.vue',				
			},
			{
				path: '/system/activity_list',
				name: '活动列表',
				url: '/activity_mng/activity_list/activity_list.vue',				
			},
			{
				path: '/system/activity_add',
				name: '新增活动',
				url: '/activity_mng/activity_list/add.vue',	
				meta:{
					ischildren:true
				}
			},
			{
				path: '/system/activity_edit',
				name: '编辑活动',
				url: '/activity_mng/activity_list/edit.vue',	
				meta:{
					ischildren:true
				}
			},
			{
				path: '/system/my_activity',
				name: '我的活动',
				url: '/activity_mng/my_activity/my_activity.vue',				
			}
		]
	},
	{
		icon: "el-icon-discount",
		name: '视察调研管理',
		children:[	
			{
				path: '/system/dy_activity',
				name: '调研活动列表',
				url: '/shichadiaoyan/activity/activity.vue',				
			},
			{
				path: '/system/dy_activity_add',
				name: '新增调研活动',
				url: '/shichadiaoyan/activity/add.vue',	
				meta:{
					ischildren:true
				}
			},
			{
				path: '/system/dy_activity_edit',
				name: '编辑调研活动',
				url: '/shichadiaoyan/activity/edit.vue',	
				meta:{
					ischildren:true
				}
			},
			{
				name: '调研会议管理',
				children:[
					{
						path: '/system/dy_meeting_list',
						name: '会议列表',
						url: '/shichadiaoyan/meeting/meeting_list/meeting_list.vue',				
					},
					{
						path: '/system/dy_meeting_add',
						name: '新增会议',
						url: '/shichadiaoyan/meeting/meeting_list/add.vue',	
						meta:{
							ischildren:true
						}
					},
					{
						path: '/system/dy_meeting_room',
						name: '会议室管理',
						url: '/shichadiaoyan/meeting/room/room.vue',				
					},
					{
						path: '/system/dy_meeting_tz',
						name: '我的会议',
						url: '/shichadiaoyan/meeting/meeting_tz/meeting_tz.vue',				
					},
					{
						path: '/system/dy_meeting_qj',
						name: '请假管理',
						url: '/shichadiaoyan/meeting/meeting_qj/meeting_qj.vue',				
					}
				]
			},
			{
				name: '调研发言管理',
				children:[
					{
						path: '/system/dy_talk_list',
						name: '调研发言征集',
						url: '/shichadiaoyan/meeting_talk/talk_list/talk_list.vue',				
					},
					{
						path: '/system/dy_talk_list_add',
						name: '新增调研发言征集',
						url: '/shichadiaoyan/meeting_talk/talk_list/add.vue',
						meta:{
							ischildren:true
						}
					},
					{
						path: '/system/dy_talk_list_edit',
						name: '修改调研发言征集',
						url: '/shichadiaoyan/meeting_talk/talk_list/edit.vue',
						meta:{
							ischildren:true
						}
					},
					{
						path: '/system/dy_talk_tz',
						name: '调研发言通知',
						url: '/shichadiaoyan/meeting_talk/talk_tz/talk_tz.vue',				
					},
					{
						path: '/system/dy_talk_allfagao',
						name: '调研发稿列表',
						url: '/shichadiaoyan/meeting_talk/talk_allfagao/talk_allfagao.vue',				
					},
					{
						path: '/system/dy_talk_myfagao',
						name: '我的发稿列表',
						url: '/shichadiaoyan/meeting_talk/talk_myfagao/talk_myfagao.vue',				
					},
					{
						path: '/system/dy_talk_fg_add',
						name: '新增调研发稿',
						url: '/shichadiaoyan/meeting_talk/add_fagao.vue',
						meta:{
							ischildren:true
						}
					},{
						path: '/system/dy_talk_fg_edit',
						name: '编辑稿件',
						url: '/shichadiaoyan/meeting_talk/edit_fagao.vue',
						meta:{
							ischildren:true
						}
					},
					{
						path: '/system/dy_talk_shaixuan',
						name: '调研发稿筛选',
						url: '/shichadiaoyan/meeting_talk/talk_shaixuan/talk_shaixuan.vue',				
					},
					{
						path: '/system/dy_talk_chushen',
						name: '调研发稿初审',
						url: '/shichadiaoyan/meeting_talk/talk_chushen/talk_chushen.vue',				
					},
					{
						path: '/system/dy_talk_fushen',
						name: '调研发稿复审',
						url: '/shichadiaoyan/meeting_talk/talk_fushen/talk_fushen.vue',				
					},
					{
						path: '/system/dy_talk_dinggao',
						name: '调研发稿定稿',
						url: '/shichadiaoyan/meeting_talk/talk_dinggao/talk_dinggao.vue',				
					},
					{
						path: '/system/dy_talk_all',
						name: '发言材料列表',
						url: '/shichadiaoyan/meeting_talk/finish_all/finish_all.vue',				
					},
					{
						path: '/system/dy_talk_mine',
						name: '我的发言材料',
						url: '/shichadiaoyan/meeting_talk/finish_mine/finish_mine.vue',				
					},
				]
			}
		]
	},
	{
		icon: "el-icon-edit-outline",
		name: '问卷调查',
		children:[	
			{
				path: '/system/question_inves_table',
				name: '问卷列表',
				url: '/question_inves/question_table/list.vue',
			},
			{
				path: '/system/question_inves_table_add',
				name: '新增问卷调查表',
				url: '/question_inves/question_table/add.vue',	
				meta:{
					ischildren:true
				}
			},
			{
				path: '/system/question_inves_table_edit',
				name: '编辑问卷调查表',
				url: '/question_inves/question_table/add.vue',	
				meta:{
					ischildren:true
				}
			},
			{
				path: '/system/question_inves_ku',
				name: '题库列表',
				url: '/question_inves/question_ku/list.vue',
			},
			{
				path: '/system/question_inves_mine',
				name: '我的问卷',
				url: '/question_inves/question_mine/list.vue',
			},
			{
				path: '/system/question_inves_type',
				name: '题库分类',
				url: '/question_inves/question_type/list.vue',
			},
		]
	},
	{
		icon: "el-icon-discount",
		name: '履职管理',
		children:[	
			{
				path: '/system/bg_lvzhi',
				name: '履职报告列表',
				url: '/lvzhi_mng/bg_lvzhi/bg_lvzhi.vue',	
				meta:{
					keepalive:true
				}
			},
			{
				path: '/system/lvzhi_add',
				name: '新增履职报告',
				url: '/lvzhi_mng/bg_lvzhi/add.vue',	
				meta:{
					ischildren:true
				}
			},
			{
				path: '/system/lvzhi_edit',
				name: '编辑履职报告',
				url: '/lvzhi_mng/bg_lvzhi/edit.vue',	
				meta:{
					ischildren:true
				}
			},
			{
				path: '/system/lvzhi_list',
				name: '履职上报列表',
				url: '/lvzhi_mng/lvzhi_list/lvzhi_list.vue',				
			},
			{
				name: '履职表管理',
				children:[
					{
						path: '/system/lvzhi_list_tb',
						name: '履职表列表',
						url: '/lvzhi_mng/table_lv/table_list.vue',				
					},
					{
						path: '/system/lvzhi_shenghe_tb',
						name: '履职表审核',
						url: '/lvzhi_mng/table_lv/table_shenghe.vue',				
					},
					{
						path: '/system/lvzhi_shenghe_pj',
						name: '履职表评级',
						url: '/lvzhi_mng/table_lv/table_ping.vue',				
					},
				],
			},
			{
				path: '/system/lvzhi_my_table',
				name: '2024委员履职表',
				url: '/lvzhi_mng/my_table/my_table.vue',				
			},
		]
	}
]

export default routerdata